import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { get, post } from "../../api";
import Button from "../../components/Button";
import Frame from "../../components/Frame";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import FrameHeader from "../../components/FrameHeader";
import moment from "moment";
import InputRow from "../../components/InputRow";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import LoadingFrame from "../../components/LoadingFrame";
import Modal from "../../components/Modal";
import Space from "../../components/Space";
import styles from "./CustomerSubscriptionDetail.module.scss";
import { useConfirm } from "material-ui-confirm";
import queryString from "query-string";
import Icon from "../../components/Icon";
import DataValue from "../../components/DataValue";
import StatusIndicator from "../../components/StatusIndicator";
import FrameFooter from "../../components/FrameFooter";
import CustomerContext from "../../contexts/Customer";
import PaymentProfile from "../../components/PaymentProfile/PaymentProfile";
import { useMediaQuery } from "react-responsive";
import TextInput from "../../components/TextInput";

export default function CustomerSubscriptionDetail({
  match: { params },
  location: { search },
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [loading, setLoading] = useState(true);
  const queryParams = queryString.parse(search);
  const { customer } = useContext(CustomerContext);
  const [subcriptionSummary, setSubcriptionSummary] = useState([]);
  const [originalsubcriptionSummary, setOriginalSubcriptionSummary] = useState(
    []
  );
  const [selectedDevicesToAssign, setSelectedDevicesToAssign] = useState([]);
  const [assigning, setAssigning] = useState(false);
  const [manageSubscription, setManageSubscription] = useState(false);
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [noLicense, setNoLicense] = useState([]);
  const [assigningLoading, setAssigningLoading] = useState(false);
  const [expiredLicense, setExpiredLicense] = useState([]);
  const [autoRenew, setAutoRenew] = useState(true);
  const [error, setError] = useState(null);
  const [cart, setCart] = useState([]);
  const [disabledAutomatic, setDisabledAutomatic] = useState(false);
  const [loadingLicenseId, setLoadingLicenseId] = useState(0);
  const [editingSub, setEditingSub] = useState(null);
  const [tempQuantity, setTempQuantity] = useState(0);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [customerPaymentProfile, setCustomerPaymentProfile] = useState(null);
  const [openAddCCModal, setOpenAddCCModal] = useState(false);
  const confirm = useConfirm();
  var selectedDevices = [];

  const handleRemoveFromCart = (index) => {
    confirm({
      description:
        "Are you sure you want to remove this item from the shopping cart?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        setCart((currentCart) => currentCart.filter((_, i) => i !== index));
      })
      .catch(() => {});
  };

  const handleAddToCart = (option, quantity = 1) => {
    let price = 240;
    switch (option) {
      case "oneYear":
        break;
      case "threeYear":
        price = 648;
        break;
      case "fiveYear":
        price = 900;
        break;
    }

    setCart((currentCart) => {
      const existingItemIndex = currentCart.findIndex(
        (item) => item.option === option
      );

      if (existingItemIndex >= 0) {
        const updatedCart = [...currentCart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          quantity: quantity,
        };
        return updatedCart;
      } else {
        const newItem = {
          option: option,
          quantity: quantity,
          price: price,
        };
        return [...currentCart, newItem];
      }
    });
  };

  useEffect(() => {
    fetchCustomerSubscriptons();
  }, []);

  function updateSubscriptionSummary(subcriptionSummary) {
    if (subcriptionSummary) {
      const allOptions = [365, 365 * 3, 365 * 5]; // all possible options

      const updatedSubscriptions = allOptions.map((option) => {
        const subscription = subcriptionSummary?.subscriptions?.find(
          (sub) => sub.duration === option
        );
        if (subscription) {
          return subscription;
        }
        return {
          license_count: 0,
          used: 0,
          duration: option,
          id: Math.floor(Math.random() * 100000),
        };
      });

      subcriptionSummary = {
        ...subcriptionSummary,
        subscriptions: updatedSubscriptions,
      };

      setSubcriptionSummary(subcriptionSummary);
      setOriginalSubcriptionSummary(subcriptionSummary);
    }
  }

  async function placeOrder() {
    try {
      setPaymentInProgress(true);
      const res = await post("/checkout/placeorder", {
        customer_id: customer.id,
        cart,
      });
      if (res) {
        if (res?.success) {
          await fetchCustomerSubscriptons();
          setPaymentInProgress(false);
          setManageSubscription(false);
          setCart([]);
        } else {
          setError(res.content);
          confirm({
            description:
              res.content ||
              "Looks like something went wrong with the payment process. Contact the Sentinel support team to troubleshoot this issue. Call 949-949-5663 or e-mail sentinelsupport@traffixdevices.com.",
            title: "Unable to place order",
            dialogProps: { fullScreen: false },
            cancellationButtonProps: { style: { display: "none" } },
            confirmationButtonProps: { color: "primary", disableRipple: true },
          })
            .then(() => {
              setPaymentInProgress(false);
            })
            .catch(() => {
              /* ... */
            });
        }
        setPaymentInProgress(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchCustomerSubscriptons() {
    setLoading(true);
    setDisabledAutomatic(false);
    setAutoRenew(true);
    setCustomerPaymentProfile(null);

    const subcriptionSummary = await Promise.all([
      get(`/customers/subscriptions`),
    ]);
    const customerPaymentProfile = await Promise.all([
      get(`/customers/paymentprofile`),
    ]);
    customerPaymentProfile &&
      customerPaymentProfile[0] &&
      customerPaymentProfile[0]?.length > 0 &&
      setCustomerPaymentProfile(customerPaymentProfile[0]);

    subcriptionSummary &&
      subcriptionSummary[0] &&
      updateSubscriptionSummary(subcriptionSummary[0]);

    subcriptionSummary &&
      subcriptionSummary[0] &&
      subcriptionSummary &&
      subcriptionSummary[0] &&
      subcriptionSummary[0].devices_with_expired_license &&
      setExpiredLicense(subcriptionSummary[0].devices_with_expired_license);

    subcriptionSummary &&
      subcriptionSummary[0] &&
      subcriptionSummary[0].devices_no_license &&
      setNoLicense(subcriptionSummary[0].devices_no_license);

    subcriptionSummary &&
      subcriptionSummary[0] &&
      subcriptionSummary[0].devices_with_expired_license.length > 0 &&
      subcriptionSummary[0].devices_with_expired_license.map((type) => {
        var id = type.id;
        selectedDevices.push(id);
      });

    setSelectedDevicesToAssign(selectedDevices);

    if (
      subcriptionSummary[0]?.subscriptions_active -
        subcriptionSummary[0]?.subscriptions_in_use -
        subcriptionSummary[0]?.devices_with_active_license.length <
      subcriptionSummary[0]?.devices_with_expired_license.length
    ) {
      if (
        subcriptionSummary[0].devices_with_expired_license.length >
        subcriptionSummary[0].licenses_active_no_device.length
      ) {
        setDisabledAutomatic(true);
        setAutoRenew(false);
        setSelectedDevicesToAssign([]);
      }
    }

    if (queryParams.assign) {
      setAssigning(true);
    }
    setLoading(false);
  }

  function activateNewLicenseForDeviceID(dev_id) {
    confirm({
      description:
        "Are you sure you want to assign a subscription for this device?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        assignAutoLicenseForDevice(dev_id);
      })
      .catch(() => {
        /* ... */
      });
  }

  async function assignAutoLicenseForDevice(device_id) {
    setLoading(true);
    var result = await post("/customer/subscriptions/auto/assign", {
      devices: [device_id],
    });
    refresh();
  }

  const handleEdit = (sub) => {
    setEditingSub(sub);

    const cartItem = cart.find(
      (item) => item.option === decodeOption(sub.duration)
    );
    if (cartItem) {
      setTempQuantity(sub.license_count + cartItem?.quantity);
    } else {
      setTempQuantity(sub.license_count);
    }
  };

  const handleSave = () => {
    if (
      tempQuantity !== 0 &&
      tempQuantity !==
        cart.find((x) => x.option == decodeOption(editingSub.duration))
          ?.quantity +
          1
    ) {
      switch (editingSub.duration) {
        case 365:
          handleAddToCart("oneYear", tempQuantity - editingSub.license_count);
          break;
        case 365 * 3:
          handleAddToCart("threeYear", tempQuantity - editingSub.license_count);
          break;
        case 365 * 5:
          handleAddToCart("fiveYear", tempQuantity - editingSub.license_count);
          break;
      }
    } else {
      if (tempQuantity == 0) {
        const updatedCart = cart.filter(
          (item) => item.option !== decodeOption(editingSub.duration)
        );
        setCart(updatedCart);
      }
    }

    setEditingSub(null);
    setTempQuantity(0);
  };

  async function assignDevices() {
    setAssigningLoading(true);
    const res = await post(`/customer/subscriptions/auto/assign`, {
      auto_assign: autoRenew,
      devices: selectedDevicesToAssign,
    });

    if (res) {
      window.location.replace("/customer/subscriptions");
    } else {
      alert("Sorry! We encountered an error!");
    }
    setAssigningLoading(false);
  }

  async function changeStateForLicenseId(subId, licenseId, state) {
    setLoadingLicenseId(licenseId);
    const res = await post(`/subscriptions/licenses/${licenseId}`, {
      state: state,
    });

    if (!res) {
      alert("Sorry! We encountered an error!");
      return;
    }

    var tempSummary = subcriptionSummary.subscriptions;
    tempSummary.map((sub) => {
      if (sub.id == subId) {
        sub.licenses.map((lic) => {
          if (lic.id == licenseId) {
            lic.auto_renew = state;
          }
        });
      }
    });

    subcriptionSummary.subscriptions = tempSummary;
    setSubcriptionSummary(subcriptionSummary);

    setLoadingLicenseId(0);
  }

  async function refresh() {
    setLoading(true);
    /*const subcriptionSummary = await Promise.all([
      get(`/customers/subscriptions`),
    ]);
    subcriptionSummary &&
      subcriptionSummary[0] &&
      setSubcriptionSummary(subcriptionSummary[0]);*/

    await fetchCustomerSubscriptons();
    setLoading(false);
  }

  function _renderModelFooter() {
    return (
      <>
        <Button onClick={() => setAssigning(false)}>Cancel</Button>
        <Button
          disabled={selectedDevicesToAssign.length === 0 || assigningLoading}
          theme="primary"
          isLoading={assigningLoading}
          onClick={() => assignDevices()}
        >
          Assign
        </Button>
      </>
    );
  }

  function _renderSubscriptionManageModelFooter() {
    return (
      <>
        <Button
          onClick={() => {
            setCart([]);
            setSubcriptionSummary(originalsubcriptionSummary);
            setManageSubscription(false);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={
            paymentInProgress || !cart.some((item) => item.quantity > 0)
          }
          theme="primary"
          isLoading={paymentInProgress}
          onClick={() => {
            const cartString = cart
              .map(
                (item) =>
                  `${decodeOptionShortCart(item.option)} x ${item.quantity}`
              )
              .join(", ");

            confirm({
              description: "Are you sure you want to place the order?",
              title: "",
              dialogProps: { fullScreen: false },
              cancellationButtonProps: { color: "error", disableRipple: true },
              confirmationButtonProps: {
                color: "primary",
                disableRipple: true,
              },
            })
              .then(() => {
                placeOrder();
              })
              .catch(() => {
                // The user cancelled the confirmation dialog.
              });
          }}
        >
          Buy
        </Button>
      </>
    );
  }

  async function modifyTypes(device_id, checked) {
    setDevicesLoading(true);

    if (checked == true) {
      var array = selectedDevicesToAssign;
      var index = array.indexOf(device_id);
      if (index < 0) {
        array.push(device_id);

        var tempArray = [];
        array.map((item) => {
          if (item != null) {
            tempArray.push(item);
          }
        });
        setSelectedDevicesToAssign(tempArray);
      }
    } else {
      var array = selectedDevicesToAssign;
      var index = array.indexOf(device_id);
      delete array[index];
      var tempArray = [];
      array.map((item) => {
        if (item != null) {
          tempArray.push(item);
        }
      });
      setSelectedDevicesToAssign(tempArray);
    }
    setDevicesLoading(false);
  }

  async function selectAll() {
    setDevicesLoading(true);
    var tempDev = [];
    expiredLicense.map((type) => {
      var id = type.id;
      tempDev.push(id);
    });
    setSelectedDevicesToAssign(tempDev);
    setDevicesLoading(false);
  }

  function selectAutomatic(value) {
    if (value === true) {
      selectAll();
    }
    setAutoRenew(value);
  }

  function stepOption(label, explanation, value) {
    const active = autoRenew === value;
    const classes = [styles.label, active && styles.labelActive].join(" ");

    return (
      <label className={classes}>
        <div className={styles.img}>
          <div className={styles[value]}>
            <Icon name={value === true ? "auto_mode" : "list"} size={56} />
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.flexRow}>
            <div className={styles.text}>{active ? <b>{label}</b> : label}</div>
          </div>
          <div className={styles.flexRow}>
            <div className={styles.sub_text}>
              <p>{explanation}</p>
            </div>
          </div>
        </div>
        <input
          type="radio"
          name="damage"
          value={value}
          disaled={disabledAutomatic}
          onChange={(e) => selectAutomatic(value)}
          checked={active}
          disabled={disabledAutomatic}
        />
      </label>
    );
  }

  function displayDevicesVsSubscriptionCounter() {
    var devicesWithNoLicense =
      subcriptionSummary.devices_with_expired_license.length;
    var available =
      subcriptionSummary.subscriptions_active -
      subcriptionSummary.subscriptions_in_use +
      subcriptionSummary.licenses_active_no_device.length;
    if (devicesWithNoLicense > available) {
      return (
        <Space size="s">
          <p className={styles.red}>
            {devicesWithNoLicense > available === 1
              ? "There is " +
                devicesWithNoLicense +
                " device with expired subscription."
              : "There are " +
                devicesWithNoLicense +
                " devices with expired subscriptions."}
          </p>
        </Space>
      );
    }
  }

  function shouldBeDisabled(dev_id) {
    return selectedDevicesToAssign.indexOf(dev_id) > -1 ? false : true;
  }

  function displayCartItems() {
    if (cart?.length > 0) {
      return (
        <>
          <div className={styles.container}>
            <div className={styles.header}>Order summary</div>
            <div className={styles.bottomsticky}>
              <div className={styles.cartFrame}>
                {cart.map((item, index) =>
                  isMobile ? (
                    <div key={index} className={styles.quantitycontrolMobile}>
                      <div className={styles.minwidth}>
                        {decodeOptionShort(item.option)}
                      </div>
                      <div className={styles.totalcart}>
                        <div className={styles.quantitycontrol}>
                          <span className={styles.span}>
                            <p> {item.quantity}</p>
                          </span>
                        </div>
                      </div>
                      <Button
                        size="s"
                        icon="delete_forever"
                        theme="danger"
                        onClick={() => handleRemoveFromCart(index)}
                      />
                    </div>
                  ) : (
                    <div key={index} className={styles.cartItem}>
                      {decodeOptionShort(item.option)}
                      <div className={styles.totalcart}>
                        <div className={styles.quantitycontrol}>
                          <span className={styles.span_small}>
                            <p> {item.quantity}</p>
                          </span>
                        </div>
                      </div>
                      <span className={styles.span_price}>
                        <b>${item.price * item.quantity}.00</b>
                      </span>

                      <Button
                        size="s"
                        icon="delete_forever"
                        theme="danger"
                        onClick={() => handleRemoveFromCart(index)}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return "Cart empty";
    }
  }

  const returnIconWrapper = (color, text) => {
    return (
      <div className={styles.flexContainer}>
        <div className={styles.iconContainer}>
          <Icon color={color} name={"calendar_month"} size={36} />
        </div>
        <div className={styles.textContainer}>{text}</div>
      </div>
    );
  };

  const decodeOptionShort = (option) => {
    if (!isMobile) {
      switch (option) {
        case "oneYear":
          return returnIconWrapper("#ee0e0e", "1 year subscription");
        case "threeYear":
          return returnIconWrapper("#f26925", "3 year subscription");
        case "fiveYear":
          return returnIconWrapper("#05b008", "5 year subscription");
      }
    } else {
      switch (option) {
        case "oneYear":
          return "1 Year";
        case "threeYear":
          return "#f26925", "3 Year";
        case "fiveYear":
          return "#05b008", "5 Year";
      }
    }
  };

  function decodeOptionShortCart(option) {
    switch (option) {
      case "oneYear":
        return "1 Year";
      case "threeYear":
        return "3 Years";
      case "fiveYear":
        return "5 Years";
      default:
        return "";
    }
  }

  const decodeOption = (option) => {
    switch (option) {
      case 365:
        return "oneYear";
      case 365 * 3:
        return "threeYear";
      case 365 * 5:
        return "fiveYear";
    }
  };

  function disabledAssigning() {
    var returner = false;
    if (
      subcriptionSummary.available_subscriptions +
        subcriptionSummary.licenses_active_no_device.length <=
      0
    )
      returner = true;
    return returner;
  }

  const DurationDisplay = (duration) => {
    const years = duration / 365;
    return <div className={styles.rowItem}>{years.toFixed(0)} year</div>;
  };

  const PriceDisplay = (duration) => {
    switch (duration) {
      case 365:
        return 240;
      case 365 * 3:
        return 648;
      case 365 * 5:
        return 900;
      default:
    }
  };

  const quietRefresh = async () => {
    const customerPaymentProfile = await Promise.all([
      get(`/customers/paymentprofile`),
    ]);
    customerPaymentProfile &&
      customerPaymentProfile[0] &&
      customerPaymentProfile[0]?.length > 0 &&
      setCustomerPaymentProfile(customerPaymentProfile[0]);

    setManageSubscription(!manageSubscription);
  };

  const displayManageSubcriptions = () => {
    return (
      <Modal
        isLoading={paymentInProgress}
        footer={_renderSubscriptionManageModelFooter()}
      >
        <FrameHeader title="Buy subscriptions" />
        <Frame padding={16}>
          <>
            <div className={styles.managelicense}>
              <div className={styles.managerow}>
                <div className={styles.rowItem}>
                  <b>Name</b>
                </div>
                <div className={styles.rowItem}>
                  <b className={styles.new}>NEW</b>
                </div>
                <div className={styles.rowItem}>
                  <b>Purchased</b>
                </div>
                <div className={styles.rowItem}>
                  <b>In Use</b>
                </div>
                <div className={styles.rowItem}>
                  <b>Available</b>
                </div>
                <div className={styles.rowItem}>
                  <b>Price/device</b>
                </div>
                <div className={styles.rowItem}>
                  <b>Total cost</b>
                </div>
              </div>

              {subcriptionSummary.subscriptions?.map((sub) => {
                return (
                  <div className={styles.managerowbigger}>
                    <div className={styles.rowItem}>
                      {DurationDisplay(sub.duration)}
                    </div>

                    <div className={styles.rowItem}>
                      <>
                        <div className={styles.totalcart}>
                          <div className={styles.quantitycontrol}>
                            <TextInput
                              className={styles.licensefield}
                              type="text"
                              value={
                                cart.find(
                                  (item) =>
                                    item.option === decodeOption(sub.duration)
                                )?.quantity || 0
                              }
                              onChange={(event) => {
                                const value = Number(event.target.value);
                                const newQuantity = Number.isInteger(value)
                                  ? value
                                  : undefined;
                                if (
                                  newQuantity !== undefined &&
                                  newQuantity >= 0
                                ) {
                                  const item = cart.find(
                                    (item) =>
                                      item.option === decodeOption(sub.duration)
                                  );
                                  if (item) {
                                    const updatedCart = cart.map((item) =>
                                      item.option === decodeOption(sub.duration)
                                        ? {
                                            ...item,
                                            quantity: value,
                                          }
                                        : item
                                    );
                                    setCart(updatedCart);
                                  } else {
                                    handleAddToCart(
                                      decodeOption(sub.duration),
                                      1
                                    );
                                  }
                                }
                              }}
                            />
                          </div>
                          <div className={styles.verticalButtons}>
                            <Button
                              size="xs"
                              theme="light"
                              icon="add"
                              onClick={() => {
                                const item = cart.find(
                                  (item) =>
                                    item.option === decodeOption(sub.duration)
                                );

                                if (item) {
                                  const updatedCart = cart.map((item) =>
                                    item.option === decodeOption(sub.duration)
                                      ? { ...item, quantity: item.quantity + 1 }
                                      : item
                                  );
                                  setCart(updatedCart);
                                } else {
                                  handleAddToCart(
                                    decodeOption(sub.duration),
                                    1
                                  );
                                }
                              }}
                            />
                            <Space size="xxxs" />

                            <Button
                              size="xs"
                              disabled={
                                cart.find(
                                  (item) =>
                                    item.option === decodeOption(sub.duration)
                                )?.quantity === 0
                              }
                              icon="remove"
                              theme="light"
                              onClick={() => {
                                const option = decodeOption(sub.duration);
                                const updatedCart = cart
                                  .map((item) =>
                                    item.option === option && item.quantity > 0
                                      ? { ...item, quantity: item.quantity - 1 }
                                      : item
                                  )
                                  .filter((item) => item.quantity > 0);
                                setCart(updatedCart);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    </div>
                    <div className={styles.rowItem}>{sub.license_count}</div>
                    <div className={styles.rowItem}>{sub.used}</div>
                    <div className={styles.rowItem}>
                      {sub.license_count - sub.used}
                    </div>
                    <div className={styles.rowItem}>
                      ${PriceDisplay(sub.duration)}
                    </div>
                    <div className={styles.rowItem}>
                      $
                      {PriceDisplay(sub.duration) *
                        (sub?.license_count +
                          +(cart.find(
                            (x) => x.option === decodeOption(sub.duration)
                          )?.quantity > 0
                            ? cart.find(
                                (x) => x.option === decodeOption(sub.duration)
                              )?.quantity
                            : 0))}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </Frame>
        {cart.length > 0 && displayCartItems()}
      </Modal>
    );
  };

  function displaySubscriptions() {
    return (
      <Frame>
        {isMobile ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "10px",
            }}
          >
            <DataValue label="Expired">
              <StatusIndicator status={"INACTIVE_EXPIRED_LICENSE"} />
              {subcriptionSummary?.licenses_expired_count}
            </DataValue>
            <div style={{ width: "80px" }} />
            <DataValue label="Expiring soon">
              <StatusIndicator status={"BATTERY_LOW"} />
              {subcriptionSummary?.subscriptions_expiring_this_cycle}
            </DataValue>
          </div>
        ) : (
          <InputRow>
            <DataValue label="Expired">
              <StatusIndicator status={"INACTIVE_EXPIRED_LICENSE"} />
              {subcriptionSummary?.licenses_expired_count}
            </DataValue>
            <DataValue label="Expiring soon">
              <StatusIndicator status={"BATTERY_LOW"} />
              {subcriptionSummary?.subscriptions_expiring_this_cycle}
            </DataValue>
            {!isMobile && (
              <>
                <Space />
                <Space />
                <Space />
                <Space />
              </>
            )}
          </InputRow>
        )}

        <>
          <div className={styles.rowFull}>
            <div className={[styles.row, styles.rowHeader].join(" ")}>
              <div className={styles.rowItem}>
                <b>Name</b>
              </div>
              <div className={styles.rowItem}>
                <b>In Use</b>
              </div>
              <div className={styles.rowItem}>
                <b>Available</b>
              </div>
              {!isMobile && (
                <div className={styles.rowItem}>
                  <b>Price/device</b>
                </div>
              )}
              <div className={styles.rowItem}>
                <b>{isMobile ? "Total" : "Total cost"}</b>
              </div>
              <div className={styles.rowItem}> </div>
            </div>
          </div>
          {subcriptionSummary?.subscriptions?.map((sub) => {
            return (
              <div className={styles.accordionWrapper}>
                <Accordion>
                  <AccordionSummary disabled={sub.license_count == 0}>
                    <div className={styles.sub_top}>
                      <div className={[styles.row, styles.rowHeader].join(" ")}>
                        <div className={styles.rowItem}>
                          {DurationDisplay(sub.duration)}
                        </div>
                        <div className={styles.rowItem}>
                          {sub.licenses_active || 0}
                        </div>
                        <div className={styles.rowItem}>
                          {sub.license_count - sub.used}
                        </div>
                        {!isMobile && (
                          <div className={styles.rowItem}>
                            ${PriceDisplay(sub.duration)}
                          </div>
                        )}
                        <div className={styles.rowItem}>
                          ${PriceDisplay(sub.duration) * sub?.license_count}
                        </div>
                        <div className={styles.rowItem}>
                          <div
                            className={[
                              styles.accordionHeader,
                              "accordionHeader",
                            ].join(" ")}
                          >
                            <div
                              className={[styles.infoText, "infoText"].join(
                                " "
                              )}
                            >
                              <Icon
                                name="double_arrow"
                                size="24"
                                color="#ee0e0e"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <div className={styles.license}>
                        <div
                          className={[styles.row, styles.rowHeader].join(" ")}
                        >
                          <div className={styles.rowItem}>
                            <b>Serial number</b>
                          </div>
                          <div className={styles.rowItem}>
                            <b>Status</b>
                          </div>
                          <div className={styles.rowItem}>
                            <b>Expiration</b>
                          </div>
                          <div className={styles.rowItem}>
                            <b>Auto renew</b>
                          </div>
                        </div>
                        {sub?.licenses?.length !== 0 &&
                          sub?.licenses?.map((lic) => (
                            <>
                              <div
                                className={[
                                  styles.row,
                                  moment(lic.expiry_date).diff(
                                    moment.utc(),
                                    "days"
                                  ) <= 31 && styles.expiringsoon,
                                ].join(" ")}
                              >
                                <div className={styles.rowItem}>
                                  {lic.device ? (
                                    <Link to={`/assets/${lic.asset_link}`}>
                                      {lic.device?.serial_number}
                                    </Link>
                                  ) : (
                                    "Not assigned"
                                  )}
                                </div>
                                <div className={styles.rowItem}>
                                  {lic.active ? (
                                    "Active"
                                  ) : (
                                    <p className={styles.expired}>
                                      <b>Expired</b>
                                    </p>
                                  )}
                                </div>
                                {lic.active ? (
                                  <div className={styles.rowItem}>
                                    {moment
                                      .utc(lic.expiry_date)
                                      .format("MMM DD YYYY")}
                                  </div>
                                ) : (
                                  <div className={styles.popover}>
                                    <Button
                                      onClick={() =>
                                        activateNewLicenseForDeviceID(
                                          lic.device_id
                                        )
                                      }
                                      disabled={
                                        subcriptionSummary.available_subscriptions +
                                          subcriptionSummary
                                            .licenses_active_no_device.length <=
                                        0
                                      }
                                    >
                                      Assign subscription
                                    </Button>
                                  </div>
                                )}
                                <div className={styles.rowItem}>
                                  <CheckboxWithLabel
                                    key={lic.id}
                                    loading={loadingLicenseId == lic.id}
                                    checked={lic.auto_renew}
                                    onChange={(e) =>
                                      changeStateForLicenseId(
                                        sub.id,
                                        lic.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </>
        <FrameFooter>
          <Button
            theme="primary"
            noHeight={true}
            onClick={() =>
              customerPaymentProfile == null
                ? setOpenAddCCModal(true)
                : setManageSubscription(!manageSubscription)
            }
          >
            Buy subscriptions
          </Button>
        </FrameFooter>
      </Frame>
    );
  }

  function renderContent() {
    return (
      <>
        <Space size="s">
          {subcriptionSummary.devices_with_expired_license && (
            <>
              {subcriptionSummary.available_subscriptions +
                subcriptionSummary.licenses_active_no_device.length >
              0
                ? subcriptionSummary.available_subscriptions +
                    subcriptionSummary.licenses_active_no_device.length >
                  1
                  ? "There are " +
                    (subcriptionSummary.available_subscriptions +
                      subcriptionSummary.licenses_active_no_device.length) +
                    " subscriptions available. Choose the serial numbers below."
                  : subcriptionSummary.available_subscriptions +
                      subcriptionSummary.licenses_active_no_device.length ==
                    0
                  ? "You do not have an available subscription."
                  : "There is 1 subscription available. Choose the serial number below."
                : "Zero devices available"}
            </>
          )}
        </Space>

        {displayDevicesVsSubscriptionCounter()}

        <div className={styles.center}>
          <InputRow>
            <div className={styles.wrap2}>
              {stepOption(
                "Automatically",
                "Choose this option if you want the subscription automatically assigned.",
                true
              )}
              {stepOption(
                "Manually",
                "Choose this option if you want to manually assign the subscription.",
                false
              )}
            </div>
          </InputRow>
        </div>
        <Space size="s" />
        {autoRenew ? (
          ""
        ) : devicesLoading == false && noLicense ? (
          <>
            <Space size="s">
              <Button
                size={"s"}
                onClick={() => selectAll()}
                disabled={disabledAutomatic}
              >
                Select All
              </Button>
              {"  "}
              <Button size={"s"} onClick={() => setSelectedDevicesToAssign([])}>
                Select None
              </Button>
            </Space>
            {devicesLoading ? (
              <LoadingFrame />
            ) : (
              expiredLicense?.map((dev) => {
                return (
                  <div key={dev.id} className={styles.filter}>
                    {
                      <CheckboxWithLabel
                        key={dev.id}
                        label={dev.serial_number}
                        checked={
                          selectedDevicesToAssign.indexOf(dev.id) > -1
                            ? true
                            : false
                        }
                        onChange={(e) => modifyTypes(dev.id, e.target.checked)}
                        disabled={
                          selectedDevicesToAssign.length + 1 >
                            subcriptionSummary.available_subscriptions +
                              subcriptionSummary.licenses_active_no_device
                                .length && shouldBeDisabled(dev.id)
                        }
                      />
                    }
                  </div>
                );
              })
            )}
          </>
        ) : (
          "No expired subscription"
        )}
      </>
    );
  }

  return loading ? (
    <LoadingFrame />
  ) : (
    <>
      {manageSubscription && displayManageSubcriptions()}
      {subcriptionSummary ? (
        <>
          <Space size="l">
            <FrameHeader title={`Subscription details`}>
              <Button
                theme="primary"
                icon="auto_fix_normal"
                noHeight={true}
                disabled={disabledAssigning() || loading}
                onClick={() => setAssigning(true)}
              >
                Subscription wizard
              </Button>
            </FrameHeader>
            {displaySubscriptions()}

            <Space />
            <PaymentProfile
              onRefresh={fetchCustomerSubscriptons}
              openAddModal={openAddCCModal}
              closeAddModal={() => setOpenAddCCModal(false)}
              quietRefresh={quietRefresh}
            />
          </Space>

          {assigning && (
            <Modal footer={_renderModelFooter()}>
              <>
                <Space size="s">
                  <h2>Subscription wizard</h2>
                </Space>
                {renderContent()}
              </>
            </Modal>
          )}
        </>
      ) : (
        <>Unable to load subscription details, sorry.</>
      )}
    </>
  );
}
